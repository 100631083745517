<template>
  <div class="app-page-layout" :style="backgroundImg">
    <slot></slot>
    <slot name="content" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ColorThemeMixin } from '../../services/vuestic-ui'

export default {
  name: 'app-page-layout',
  mixins: [ColorThemeMixin],
  props: {
    mobileWidth: {
      type: Number,
      default: 767,
    },
  },
  data () {
    return {
      backgroundImg: '',
    }
  },
  computed: {
    ...mapGetters(['backgroundImage', 'backgroundType']),
  },
  watch: {
    backgroundImage (val) {
      this.setBackgroundImg()
    },
  },
  created () {
    this.setBackgroundImg()
  },
  mounted () {
    window.addEventListener('resize', () => this.updateSidebarState())
    this.updateSidebarState()
  },
  beforeDestroy () {
    window.removeEventListener('resize', () => this.updateSidebarState())
  },
  methods: {
    checkIsDesktop () {
      return window.matchMedia(`(min-width: ${this.mobileWidth}px)`).matches
    },
    updateSidebarState () {
      if (this.checkIsDesktop()) {
        const sidebar = window.localStorage.getItem('sidebar_minimized')
        this.$emit('update:minimized', !!JSON.parse(sidebar))
      } else {
        this.$emit('update:minimized', true)
      }
    },
    setBackgroundImg () {
      const c = this.backgroundImg || ''
      if (this.backgroundType === 'image') {
        this.backgroundImg = `background-image: url('${c}')`
      } else {
        const background = this.$themes.background
        this.logoImg = `background-color: ${background}`
      }
    },
  },
}
</script>

<style lang="scss">

.app-page-layout {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .content-wrap {
    transition: margin-left 0.3s ease;
    padding: 0;
    margin-left: 0 !important;
  }
}
</style>
